<template>
  <div>
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt=""
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-6xl font-medium" style="color: black;">404</div>
          <div class="intro-x text-xl lg:text-3xl font-medium" style="color: black;">
            Oops. This page has gone missing.
          </div>
          <div class="intro-x text-lg mt-3" style="color: black;">
            You may have mistyped the address or the page may have moved.
          </div>
          <button
            class="intro-x button button--lg border border-white mt-10"
            style="color:#FFF;background:linear-gradient(to bottom, #1C3FAA, #2B51B4);border:none"
            @click="onFanHui"
          >
           返回上一页
          </button>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    cash("body")
      .removeClass("app")
      .addClass("login");
  },
  methods:{
    onFanHui:  function(){
       this.$router.push('/Dashboard');
    }
  }
};
</script>
